import React from 'react';
import ReactDOM from 'react-dom';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css'; // for ES6 modules
import '../node_modules/react-widgets/lib/scss/react-widgets.scss';
import './assets/scss/custom.scss';
import './assets/scss/style.scss';
import Root from './routes';
import * as serviceWorker from './serviceWorker';
import './vendors/element-icons.ttf';
import './vendors/line-awesome/css/line-awesome.min.css';
// import './assets/scss/bootstrap.min.css';

ReactDOM.render(<Root />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
