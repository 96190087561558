import React, { Component } from 'react';
import logo from '../assets/images/logo.png';
import { withRouter } from 'react-router-dom';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let location = window.location.href;
    location = location.toString();
    return (
      <React.Fragment>
        <div className="companyLogo">
          <div className="el-row">
            <div className="el-col el-col-10">
              <img src={logo} alt="logo" />
            </div>
            {/* <div className="el-col el-col-4">
              <p className="logoText">Veera</p>
            </div> */}
          </div>
        </div>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/dashboard') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/dashboard');
          }}
        >
          <span className="icon">
            <i className="la la-dashboard" />
          </span>
          <span className="icondata">DASHBOARD</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/doctors') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/doctors');
          }}
        >
          <span className="icon">
            <i className="la la-stethoscope" />
          </span>
          <span className="icondata">DOCTORS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/patients') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/patients');
          }}
        >
          <span className="icon">
            <i className="la la-user" />
          </span>
          <span className="icondata">PATIENTS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/appointments') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/appointments');
          }}
        >
          <span className="icon">
            <i className="la la-calendar" />
          </span>
          <span className="icondata">APPOINTMENTS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/languages') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/languages');
          }}
        >
          <span className="icon">
            <i className="la la-language" />
          </span>
          <span className="icondata">LANGUAGES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/health-issues') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/health-issues');
          }}
        >
          <span className="icon">
            <i className="la la-wheelchair" />
          </span>
          <span className="icondata">HEALTH ISSUES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/provider-types') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/provider-types');
          }}
        >
          <span className="icon">
            <i className="la la-list" />
          </span>
          <span className="icondata">PROVIDER TYPES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/specialities') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/specialities');
          }}
        >
          <span className="icon">
            <i className="la la-star-o" />
          </span>
          <span className="icondata">SPECIALITIES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/stories') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/stories');
          }}
        >
          <span className="icon">
            <i className="la la-paste" />
          </span>
          <span className="icondata">STORIES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/articles') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/articles');
          }}
        >
          <span className="icon">
            <i className="la la-newspaper-o" />
          </span>
          <span className="icondata">ARTICLES</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/comments') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/comments');
          }}
        >
          <span className="icon">
            <i className="la la-comments" />
          </span>
          <span className="icondata">COMMENTS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/ledger') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/ledger');
          }}
        >
          <span className="icon">
            <i className="la la-dollar" />
          </span>
          <span className="icondata">TRANSACTIONS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/settlements') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/settlements');
          }}
        >
          <span className="icon">
            <i className="la la-dollar" />
          </span>
          <span className="icondata">SETTLEMENTS</span>
        </li>
        <li
          role="menuitem"
          tabIndex={-1}
          className={`el-menu-item ${
            location.includes('/settings') ? 'is-active' : ''
          }`}
          style={{ paddingLeft: '20px' }}
          onClick={() => {
            this.props.history.push('/settings');
          }}
        >
          <span className="icon">
            <i className="la la-gear" />
          </span>
          <span className="icondata">SETTINGS</span>
        </li>
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
