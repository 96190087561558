import React from 'react';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubmenu: false
    };
  }

  getTitle = () => {
    let location = window.location.href;
    location = location.toString();

    if (location.includes('/dashboard')) {
      return 'Dashboard';
    } else if (location.includes('/languages')) {
      return 'Languages';
    } else if (location.includes('/health-issues')) {
      return 'Health Issues';
    } else if (location.includes('/patients')) {
      return 'Patients';
    } else if (location.includes('/appointments')) {
      return 'Appointments';
    } else if (location.includes('/doctors')) {
      return 'Doctors';
    } else if (location.includes('/articles')) {
      return 'Articles';
    } else if (location.includes('/stories')) {
      return 'Stories';
    } else if (location.includes('/provider-types')) {
      return 'Provider Types';
    } else if (location.includes('/specialities')) {
      return 'Specialities';
    } else if (location.includes('/settings')) {
      return 'Settings';
    } else if (location.includes('/comments')) {
      return 'Comments';
    } else if (location.includes('/ledger')) {
      return 'Transactions';
    } else if (location.includes('/settlements')) {
      return 'Settlements';
    }
  };

  render() {
    let title = this.getTitle();
    return [
      <div className="pageTitle" tabIndex={0}>
        {/* <i className="la la-bars" /> */}
        <span className="headertitle">{title}</span>
      </div>,
      <li
        role="menuitem"
        aria-haspopup="true"
        className={`el-submenu`}
        tabIndex={0}
        style={{ float: 'right' }}
        onClick={() => {
          this.props.openSubmenu();
        }}
      >
        <div
          className="el-submenu__title"
          style={{ borderBottomColor: 'transparent' }}
        >
          Hi Admin
          <i className="la la-angle-down" style={{ padding: '0 10px' }} />
        </div>
        <div className="el-menu--horizontal" style={{ display: 'none' }}>
          <ul
            role="menu"
            className="el-menu el-menu--popup el-menu--popup-bottom-start"
          >
            <li role="menuitem" tabIndex={-1} className="el-menu-item" onClick={() => { }}>
              Logout
            </li>
          </ul>
        </div>
      </li>
    ];
  }
}

export default Header;
