import React from 'react';

const Footer = () => {
  return ([
    <div className="el-col el-col-18">©2019 Veera Health - All Rights Reserved.</div>,
    // <div className="el-col el-col-6" style={{ textAlign: 'right' }}>About Workshop</div>
  ]);
};

export default Footer;
