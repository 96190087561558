import React from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from '../components/footer';

const DefaultLayout = ({ component: Component, ...rest }) => {
  return (
    <div id="appDiv">
      <Route {...rest} component={Component} />
      {/* <div className="footer el-row" style={{position:'absolute',left:'0',right:'0',bottom:'0'}}> */}
      <div className="footer el-row">
        <Footer />
      </div>
      <ToastContainer autoClose={3000} position={'bottom-right'} hideProgressBar={true}/>
    </div>
  );
};

export default DefaultLayout;
