export default {
  API: {
    Url: process.env.REACT_APP_API_URL || 'https://api.veerahealth.com',
  },
  ROLE: {
    SCOPE: 'admin',
  },
  BASIC_AUTH: {
    USERNAME: 'veera-admin-portal',
    PASSWORD: 'EAGLEISONTHEMOVE',
  },
  ENCRYPTION_SECRET_KEY: 'secret',
};
