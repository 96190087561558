import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Async from 'react-code-splitting';
import DashboardLayout from './components/dashboard-layout';
import DefaultLayout from './components/default-layout';
import withRestrictAuth from './components/with-restrict-auth';
import withAuthorised from './components/with-authorised';

const Login = props => (
  <Async load={import('./screens/login.screen')} componentProps={props} />
);
const Dashboard = props => (
  <Async
    load={import('./screens/dashboard/dashboard.screen')}
    componentProps={props}
  />
);
const Mentors = props => (
  <Async
    load={import('./screens/dashboard/mentors.screen')}
    componentProps={props}
  />
);
const AddMentors = props => (
  <Async load={import('./screens/add-mentors.screen')} componentProps={props} />
);
const PatientsScreen = props => (
  <Async
    load={import('./screens/patients/listing.screen')}
    componentProps={props}
  />
);
const PatientsDetailsScreen = props => (
  <Async
    load={import('./screens/patients/details.screen')}
    componentProps={props}
  />
);
const DoctorsScreen = props => (
  <Async
    load={import('./screens/doctors/listing.screen')}
    componentProps={props}
  />
);
const DoctorsDetailsScreen = props => (
  <Async
    load={import('./screens/doctors/details.screen')}
    componentProps={props}
  />
);
const StoriesScreen = props => (
  <Async
    load={import('./screens/stories/listing.screen')}
    componentProps={props}
  />
);
const StoriesDetailsScreen = props => (
  <Async
    load={import('./screens/stories/details.screen')}
    componentProps={props}
  />
);
const ArticlesScreen = props => (
  <Async
    load={import('./screens/articles/listing.screen')}
    componentProps={props}
  />
);
const ArticlesDetailsScreen = props => (
  <Async
    load={import('./screens/articles/details.screen')}
    componentProps={props}
  />
);
const AppointmentsScreen = props => (
  <Async
    load={import('./screens/appointments/listing.screen')}
    componentProps={props}
  />
);
const AppointmentsDetailsScreen = props => (
  <Async
    load={import('./screens/appointments/details.screen')}
    componentProps={props}
  />
);
const LanguagesScreen = props => (
  <Async
    load={import('./screens/languages/listing.screen')}
    componentProps={props}
  />
);
const HealthIssuesScreen = props => (
  <Async
    load={import('./screens/healthIssues/listing.screen')}
    componentProps={props}
  />
);
const ProviderTypesScreen = props => (
  <Async
    load={import('./screens/providerTypes/listing.screen')}
    componentProps={props}
  />
);
const CommentsScreen = props => (
  <Async
    load={import('./screens/comments/listing.screen')}
    componentProps={props}
  />
);
const AccountsScreen = props => (
  <Async
    load={import('./screens/accounts/listing.screen')}
    componentProps={props}
  />
);
const PaymentScreen = props => (
  <Async
    load={import('./screens/accounts/payment-detail.screen')}
    componentProps={props}
  />
);

const SettlementScreen = props => (
  <Async
    load={import('./screens/settlements/listing.screen')}
    componentProps={props}
  />
);

const SpecialitiesDetailsScreen = props => (
  <Async
    load={import('./screens/specialities/speciality-detail.screen')}
    componentProps={props}
  />
);
const SpecialitiesScreen = props => (
  <Async
    load={import('./screens/specialities/listing.screen')}
    componentProps={props}
  />
);
const SettingsScreen = props => (
  <Async
    load={import('./screens/settings/listing.screen')}
    componentProps={props}
  />
);
const TestimonialDetailScreen = props => (
  <Async
    load={import('./screens/settings/testimonial-details.screen')}
    componentProps={props}
  />
);
const supportsHistory = 'pushState' in window.history;

const Routes = props => {
  return (
    <Router forceRefresh={!supportsHistory}>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <DefaultLayout
          exact
          path="/login"
          component={withRestrictAuth(Login)}
        />
        <DefaultLayout
          exact
          path="/new-mentor"
          component={withAuthorised(AddMentors)}
        />
        <DashboardLayout
          exact
          path="/dashboard"
          component={withAuthorised(Dashboard)}
        />
        <DashboardLayout path="/mentors" component={withAuthorised(Mentors)} />

        <DefaultLayout
          path="/patients/:patientID"
          component={PatientsDetailsScreen}
        />
        <DashboardLayout
          path="/patients"
          component={withAuthorised(PatientsScreen)}
        />
        <DefaultLayout
          path="/doctors/:doctorID"
          component={DoctorsDetailsScreen}
        />
        <DashboardLayout
          path="/doctors"
          component={withAuthorised(DoctorsScreen)}
        />
        <DefaultLayout
          path="/appointments/:appointmentID"
          component={AppointmentsDetailsScreen}
        />
        <DashboardLayout
          path="/appointments"
          component={withAuthorised(AppointmentsScreen)}
        />
        <DefaultLayout
          exact
          path="/settings/testimonials/add-new"
          component={withAuthorised(TestimonialDetailScreen)}
        />
        <DefaultLayout
          exact
          path="/settings/testimonials/:testimonialID"
          component={withAuthorised(TestimonialDetailScreen)}
        />
        <DashboardLayout
          exact
          path="/settings"
          component={withAuthorised(SettingsScreen)}
        />
        <DefaultLayout
          path="/stories/:storyID"
          component={withAuthorised(StoriesDetailsScreen)}
        />
        <DashboardLayout
          path="/stories"
          component={withAuthorised(StoriesScreen)}
        />
        <DefaultLayout
          path="/articles/add-article"
          component={withAuthorised(ArticlesDetailsScreen)}
        />
        <DefaultLayout
          path="/articles/:articleID"
          component={withAuthorised(ArticlesDetailsScreen)}
        />
        <DashboardLayout
          path="/articles"
          component={withAuthorised(ArticlesScreen)}
        />
        <DashboardLayout
          path="/languages"
          component={withAuthorised(LanguagesScreen)}
        />
        <DashboardLayout
          path="/health-issues"
          component={withAuthorised(HealthIssuesScreen)}
        />
        <DashboardLayout
          path="/provider-types"
          component={withAuthorised(ProviderTypesScreen)}
        />
        <DashboardLayout
          path="/comments"
          component={withAuthorised(CommentsScreen)}
        />
        <DashboardLayout
          path="/ledger"
          component={withAuthorised(AccountsScreen)}
        />
        <DefaultLayout
          path="/payment/:ledgerID/:outstandingAmount"
          component={withAuthorised(PaymentScreen)}
        />
        <DefaultLayout
          path="/payment"
          component={withAuthorised(PaymentScreen)}
        />

        <DashboardLayout
          path="/settlements"
          component={withAuthorised(SettlementScreen)}
        />

        <DefaultLayout
          path="/specialities/add-speciality"
          component={withAuthorised(SpecialitiesDetailsScreen)}
        />
        <DefaultLayout
          path="/specialities/:specialityId"
          component={withAuthorised(SpecialitiesDetailsScreen)}
        />
        <DashboardLayout
          path="/specialities"
          component={withAuthorised(SpecialitiesScreen)}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
