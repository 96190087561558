import React from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import Header from './header';
import * as UserStoreService from '../services/user-store.service';

// import { getWindowDimensions } from '../helpers/window-dimensions.helper';

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);

    // let { width } = getWindowDimensions();
    this.state = {
      openSubmenu: false
    };
  }

  render() {
    let { component: Component, ...rest } = this.props;
    // let { isDrawerOpen } = this.state;
    return [
      <ul role="menubar" className="sideMenu el-menu" style={{}}>
        <Sidebar />
      </ul>,
      <div id="appDiv">
        <ul
          role="menubar"
          className="el-menu-demo topHeader el-menu--horizontal el-menu"
        >
          <Header openSubmenu={() => { this.setState({ openSubmenu: !this.state.openSubmenu }); }} />
        </ul>
        <div className="page-content-wrapper">
          <Route {...rest} component={Component} />
        </div>
        <div className="footer el-row">
          <Footer />
        </div>
        <div
          className="el-menu--horizontal"
          style={{
            position: 'absolute',
            top: '60px',
            right: '25px',
            zIndex: 2014,
            display: `${this.state.openSubmenu == true ? '' : 'none'}`
          }}
          x-placement="bottom-start"
        >
          <ul
            role="menu"
            className="el-menu el-menu--popup el-menu--popup-bottom-start"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <li role="menuitem" tabIndex={-1} className="el-menu-item" onClick={()=>{UserStoreService.logout()}}>
              Logout
            </li>
          </ul>
        </div>
      </div>,
      <ToastContainer autoClose={3000} position={'bottom-right'} hideProgressBar={true}/>
    ];
  }
}

export default DashboardLayout;
