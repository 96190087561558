import React from 'react';
import { Redirect } from 'react-router-dom';
import * as UserStoreService from '../services/user-store.service';

export default function withAuthorised(AuthorisedComponent) {
  return function Authenticate(props) {
    let loggedInUser = UserStoreService.get();
    if (loggedInUser && loggedInUser.access_token) {
      return <AuthorisedComponent {...props} />;
    }
    return <Redirect exact to="/login" />;
  };
}
