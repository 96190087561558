import { encrypt, decrypt } from './crypto.service';
import moment from 'moment';

export function get(key) {
  let nodeEnv = process.env.NODE_ENV;
  let user;
  if (nodeEnv === 'development') {
    let storedUser = window.localStorage.getItem('user');
    user = storedUser ? JSON.parse(storedUser) : undefined;
  } else {
    let ciphertext = window.localStorage.getItem('user');
    user = ciphertext ? decrypt(ciphertext) : undefined;
  }
  if (key) {
    return user ? user[key] || undefined : undefined;
  }
  return user;
}

export function set(data, key) {
  if (!data) {
    return;
  }
  let nodeEnv = process.env.NODE_ENV;
  let ciphertext;
  if (nodeEnv === 'development') {
    if (key) {
      let storedUser = window.localStorage.getItem('user');
      let user = storedUser ? JSON.parse(storedUser) : {};
      user[key] = data;
      window.localStorage.setItem('user', JSON.stringify(user));
    } else {
      window.localStorage.setItem('user', JSON.stringify(data));
    }
  } else {
    if (key) {
      let cText = window.localStorage.getItem('user');
      let user = cText ? decrypt(cText) : {};
      user[key] = data;
      ciphertext = encrypt(user);
    } else {
      ciphertext = encrypt(data);
    }
    window.localStorage.setItem('user', ciphertext);
  }
}

export function remove(key) {
  let nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === 'development') {
    if (key) {
      let storedUser = window.localStorage.getItem('user');
      let user = storedUser ? JSON.parse(storedUser) : {};
      user[key] = undefined;
      window.localStorage.setItem('user', encrypt(user));
    } else {
      window.localStorage.removeItem('user');
    }
  } else {
    if (key) {
      let cText = window.localStorage.getItem('user');
      let user = decrypt(cText);
      user[key] = undefined;
      window.localStorage.setItem('user', encrypt(user));
    } else {
      window.localStorage.removeItem('user');
    }
  }
}

export function logout() {
  window.localStorage.clear();
  window.location.href = '/login';
}
