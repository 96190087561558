import { AES, enc } from 'crypto-js';
import Const from '../common/constants';

export function encrypt(data) {
  let secretKey = Const.ENCRYPTION_SECRET_KEY;
  return AES.encrypt(JSON.stringify(data), secretKey); // output = <ciphertext>
}
export function decrypt(ciphertext) {
  let secretKey = Const.ENCRYPTION_SECRET_KEY;

  var bytes = AES.decrypt(ciphertext.toString(), secretKey);
  return JSON.parse(bytes.toString(enc.Utf8));
}
