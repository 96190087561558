import React from 'react';
import { Redirect } from 'react-router-dom';
import * as UserStoreService from '../services/user-store.service';

export default function withRestrictAuth(Component) {
  return function RestrictAuth(props) {
    let loggedInUser = UserStoreService.get();
    if (loggedInUser && loggedInUser.access_token) {
      return <Redirect exact to="/dashboard" />;
    }
    return <Component {...props} />;
  };
}
